import { useState, useEffect } from "react";
import { useConnectWallet, useWallets } from "@web3-onboard/react";
import { useSetChain } from "@web3-onboard/react";
import { ethers } from "ethers";
import bigInt from "big-integer";

import { CHAIN_ID_TO_RPC } from "../utils/chainName";

export const useConnect = () => {
  const [{ wallet, connecting }, connect, disconnect] = useConnectWallet();

  const connectWallet = async () => {
    if (!wallet) await connect({});
  };

  const disconnectWallet = async () => {
    if (wallet) await disconnect({ label: wallet.label });
  };

  return { wallet, connectWallet, disconnectWallet, connecting };
};

export const useAccount = () => {
  const connectedWallets = useWallets();
  const account = connectedWallets.length > 0 ? connectedWallets[0] : null;
  return {
    address: account?.accounts[0].address,
    wallet: account,
    isConnected: !!account,
  };
};

export const useNetwork = () => {
  const [{ connectedChain }] = useSetChain();
  return { chain: connectedChain };
};

export const useSwitchNetwork = () => {
  const [{ settingChain }, setChain] = useSetChain();

  const switchNetwork = async (chainId) => {
    await setChain({ chainId });
  };

  return { switchNetwork, settingChain };
};

export const useBalance = ({ address, token, watch = false, chainId }) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const { chainId: currentChainId } = useNetwork();
  const [{ wallet }] = useConnectWallet();
  const [provider, setProvider] = useState(null);

  useEffect(() => {
    if (chainId) {
      const rpcUrl = CHAIN_ID_TO_RPC[chainId];
      if (rpcUrl) {
        const newProvider = new ethers.providers.JsonRpcProvider(
          rpcUrl,
          chainId
        );
        setProvider(newProvider);
      } else {
        console.error(`Unsupported chainId: ${chainId}`);
      }
    } else if (wallet?.provider) {
      setProvider(new ethers.providers.Web3Provider(wallet.provider, "any"));
    }
  }, [wallet, chainId]);

  useEffect(() => {
    // if (!address || (chainId && chainId !== currentChainId)) return;

    const fetchBalance = async () => {
      try {
        let balance;
        let decimals = 18; // Default for native tokens (e.g., ETH)
        let symbol = "ETH"; // Default symbol

        if (token) {
          const erc20Abi = [
            "function balanceOf(address) view returns (uint256)",
            "function decimals() view returns (uint8)",
            "function symbol() view returns (string)",
          ];
          const contract = new ethers.Contract(token, erc20Abi, provider);

          // Fetch token metadata
          decimals = await contract.decimals();
          symbol = await contract.symbol();

          // Fetch token balance
          const rawBalance = await contract.balanceOf(address);
          balance = bigInt(rawBalance.toString()); // Convert to bigint
        } else {
          // Fetch native token balance
          const rawBalance = await provider.getBalance(address);
          balance = bigInt(rawBalance); // Convert to bigint
        }

        // Format balance based on decimals
        const formatted = ethers.utils.formatUnits(
          balance.toString(),
          decimals
        );

        // Set the data in Wagmi-like structure
        setData({
          decimals,
          symbol,
          value: balance, // Raw balance as bigint
          formatted: parseFloat(formatted), // Formatted string with 5 decimals
        });
      } catch (err) {
        setError(err.message);
      }
    };

    if (provider && address) {
      fetchBalance(); // Fetch balance on mount
    }
    let interval;
    if (watch) {
      interval = setInterval(fetchBalance, 10000); // Poll every 10 seconds
    }

    return () => {
      if (interval) clearInterval(interval); // Cleanup polling
    };
  }, [address, provider, token, watch, currentChainId]);

  return { data, error };
};

export const useContractRead = ({
  address,
  abi,
  functionName,
  args = [],
  account,
  enabled,
}) => {
  const [data, setData] = useState(null);
  const [{ wallet }] = useConnectWallet();
  const [provider, setProvider] = useState(null);

  useEffect(() => {
    if (wallet?.provider) {
      setProvider(new ethers.providers.Web3Provider(wallet.provider, "any"));
    }
  }, [wallet]);

  useEffect(() => {
    const fetchContractData = async () => {
      try {
        const contract = new ethers.Contract(address, abi, provider);
        const result = await contract[functionName](...args);
        setData(result);
      } catch (error) {
        //console.log("Contract read error:", error);
        setData(null);
      }
    };
    if (provider && abi && address && functionName && enabled) {
      fetchContractData();
    }
  }, [provider, address, abi, functionName, args, account, enabled]);

  return { data };
};

export const writeContract = async ({
  address,
  abi,
  functionName,
  args = [],
  provider = null,
}) => {
  try {
    const signer = provider.getSigner();
    const contract = new ethers.Contract(address, abi, signer);

    const tx = await contract[functionName](...args);
    const receipt = await tx.wait();

    return { hash: tx.hash, receipt };
  } catch (error) {
    console.error("Contract write error:", error);
    return { error: error.message };
  }
};

export const readContract = async ({
  address,
  abi,
  functionName,
  args = [],
  provider = null,
}) => {
  try {
    const signer = provider.getUncheckedSigner();
    const contract = new ethers.Contract(address, abi, signer);

    const result = await contract[functionName](...args);
    //console.log(`Result of ${functionName}:`, result);
    return result;
  } catch (error) {
    console.error("Contract read error:", error);
    throw error;
  }
};
