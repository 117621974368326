import { createContext, useContext, useEffect, useState } from "react";
import { initWeb3Onboard } from "./services";
import { Web3OnboardProvider as OnboardProvider } from "@web3-onboard/react";

const OnboardContext = createContext(null);

// Custom hook to use onboard instance throughout the app
export const useOnboard = () => useContext(OnboardContext);

export const Web3OnboardProvider = ({ children }) => {
  const [onboard, setOnboard] = useState(null);

  useEffect(() => {
    const onboardInstance = initWeb3Onboard(); // Call the init method
    setOnboard(onboardInstance);
    // }
  }, []);

  if (!onboard) return <div>Onboarding Provider......</div>; // Prevent hook usage before init

  return (
    <OnboardContext.Provider value={onboard}>
      <OnboardProvider web3Onboard={onboard}>{children}</OnboardProvider>
    </OnboardContext.Provider>
  );
};
