import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./assets/style/main.scss";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import { store } from "./store";
import { Provider } from "react-redux";
import { Web3OnboardProvider } from "./Web3Provider";

// export const race = {
//   id: Number(process.env.REACT_APP_L2_CHAIN_ID),
//   name: process.env.REACT_APP_L2_CHAIN_NAME,
//   network: process.env.REACT_APP_L2_NETWORK_NAME,
//   iconUrl: "https://i.imgur.com/Q3oIdip.png",
//   iconBackground: "#000000",
//   nativeCurrency: {
//     decimals: 18,
//     name: "ETHEREUM",
//     symbol: "ETH",
//   },
//   rpcUrls: {
//     default: {
//       http: [process.env.REACT_APP_L2_RPC_URL],
//     },
//   },
//   blockExplorers: {
//     default: {
//       name: process.env.REACT_APP_L2_EXPLORER,
//       url: process.env.REACT_APP_L2_EXPLORER_URL,
//     },
//   },
//   testnet: false,
// };

// const { chains, publicClient } = configureChains(
//   [mainnet, race],
//   [
//     jsonRpcProvider({
//       rpc: (chain) => ({ http: chain.rpcUrls.default.http[0] }),
//     }),
//   ]
// );

// export const connectors = [
//   new MetaMaskConnector({
//     chains,
//     options: {
//       shimDisconnect: false,
//     },
//   }),
// ];

// const config = createConfig({
//   autoConnect: true,
//   connectors,
//   storage: createStorage({ storage: window.localStorage }),
//   publicClient,
// });
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Web3OnboardProvider>
    <Provider store={store}>
      <App />
    </Provider>
  </Web3OnboardProvider>
);
reportWebVitals();
